<template>
    <div class="bg-primary">
        <div class="container full-height p-4 fl-y-cc text-black-50">
            <div class="card  p-3 text-black-50">
                <transition name="fade" mode="out-in">
                    <s-form key="'login" role="form" @submit="loginClicked" class="contact-form">
                        <div class="fl-x fl-j-c logo-text">
                            RED CARPET
                        </div>
                        <div class="text-center">
                            <h3 class="text-center text-primary">Login</h3>
                            <p class="text-center text-secondary">Login with your Credential</p>
                        </div>
                        <validated-input v-model="model.email" type="email" class="mx-4" label="Email"/>
                        <validated-input v-model="model.password" type="password" label="Password" class="mx-4 mb-3"/>
                        <div class="bg-danger p-3 mx-4 round-1" style="text-align: center;" v-if="errorMessage"
                             v-html="errorMessage"></div>

                        <div class="mt-3 text-center fl-x fl-j-c px-4 py-3">
                                                        <btn color="primary" type="submit" size="md" block :loading="loading" class="px-6"
                                                             loading-text="Validating Credential.."
                                                             text="Login"/>
                        </div>
                    </s-form>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    props: {
        info_message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loading: false,
            loginUrl: urls.auth.login,
            errorMessage: '',
            model: {
                email: '',
                password: ''
            },
            rules: {
                email: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            console.log('data', json);
            if (json.email) {
                that.setUser(json);
                that.loading = false;
                that.$router.push({ path: '/admin/' });
            } else {
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.logo-img {
    width: 16.75rem;
}
</style>
